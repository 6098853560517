<template>
  <div>
      
    <div style="min-width: 400px;" class="container mt-4 mb-4" v-if="status === 'complete'">
      <div class="col-12 text-center">
        <i class="fa fa-check-circle" style="font-size: 96px; color: #8dda8d;"></i>
      </div>

      <div class="d-flex justify-content-center mb-8 mt-8">
        <span class="text-center" style="font-weight: bold; font-size: 26px;">{{ $t('BANKID.COMPLETE') }}</span>
      </div>
  
    </div>

    <div style="min-width: 400px;" class="container mt-4 mb-4" v-else-if="status === 'failed'">
      <div class="col-12 text-center">
        <i class="fa fa-minus-circle" style="font-size: 96px; color: #fe0000;"></i>
      </div>

      <div class="d-flex justify-content-center mb-8 mt-8">
        <span class="text-center" style="font-weight: bold; font-size: 26px;">{{ $t('BANKID.FAILED') }}</span>
      </div>
  
    </div>
    
    <div class="container" v-else>

      <img :src="require('@/assets/images/BankID_MasterBrand/BankID_logo_black.png')" alt="Logo" class="logo">
      <div class="text">{{ $t('BANKID.SCAN_QR')}}</div>
    
      <vue-qrcode
        v-if="qrStartToken"
        :value="qr_value"
        :width="480"
        style="width: 95%"
      />
      <div v-else>
        <div class="d-flex justify-content-center mb-8 mt-8">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>

    </div>
    
  </div>

</template>

<script>
import { getDeviceId } from '@/core/services/deviceId';
import { mapState } from 'vuex';
import VueQrcode from 'vue-qrcode';

import axios from 'axios';

export default {
  name: 'BankIDQrCodeComponent',
  components: {
    VueQrcode
  },
  props: ['intent','type'],
  data() {
    return {
      status: 'pending',
      deviceId: null,
      qrStartToken: null,
      qrAuthCode: null,
      interval_id: null,
      seconds: 0,
      timeout: false
    };
  },
  
  async mounted() {
    this.deviceId = getDeviceId();

    this.start_period_fetch_qr_code();
  },

  destroyed() {
    clearInterval(this.interval_id);
  },
  
  methods: {

    start_period_fetch_qr_code() {

      // make a first initial fetch
      this.fetch_qr_code();

      this.interval_id = setInterval(() => {
        this.fetch_qr_code();
      }, 3000);
    },

    async fetch_qr_code() {
      try {
        const res = await axios.post(`/native/bankid/${this.type}`, {
          deviceId: this.deviceId,
          qrStartToken: this.qrStartToken,
          userAgent: window.navigator.userAgent,
          intent: this.intent
        });

        if (res.status === 200) {

          this.status = res.data.status;

          this.qrStartToken = res.data.qrStartToken;
          this.qrAuthCode = res.data.qrAuthCode;
          this.seconds = res.data.seconds;

          if (this.status === 'complete' || this.status === 'failed' || this.status === 'authenticated') {
            clearInterval(this.interval_id);
          }

          if (this.status === 'authenticated') {
            this.$emit('authenticated', res.data);
          }
          else if (this.status === 'failed') {
            this.$emit('failed');
          }
        }
        else if (res.status === 409) {
          // timeout
          // force a new auth
          this.qrStartToken = null;
          this.qrAuthCode = null;
          this.seconds = 0;
        }
      }
      catch (err) {
        console.error('fetch_qr_code', err);
      }
    },

  },
  computed: {
    qr_value() {
      return `bankid.${this.qrStartToken}.${this.seconds}.${this.qrAuthCode}`;
    },
    calc_big_qr_width() {
      return 256;
    },
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>

<style lang="css" scoped>

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid #dedede;

  max-width: 680px;

  border-radius: 12px;
}

.logo {
  width: 128px;
  height: auto;
}

.text {
  font-size: 14px;
  margin: 2px 0;
}

.bottom-image {
  width: 480px;
  height: auto;
}

</style>